<template>
  <div>
    <div style="padding: 10px">
      <el-button type="primary" size="mini" @click="importShow"
        >导入商品数据</el-button
      >
      <el-button type="warning" size="mini" @click="selDel">批量删除</el-button>
      <!-- <el-button type="danger" size="mini">全部删除</el-button> -->
    </div>

    <el-divider content-position="left">{{ disName }}</el-divider>

    <div class="disListDiv">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        height="600"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>

        <el-table-column prop="id" label="id" width="80" align="center">
        </el-table-column>

        <el-table-column label="图片" width="150" align="center">
          <template slot-scope="scope">
            <img
              height="60"
              :src="`http://hwimg.xmvogue.com/thumb/${scope.row.goods_code}.jpg?x-oss-process=style/440`"
              class="mr-3"
              alt="..."
            />
          </template>
        </el-table-column>

        <el-table-column
          prop="goods_code"
          label="产品编码"
          show-overflow-tooltip
          width="250"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="item_name" label="中文产品名" align="center">
          <template slot-scope="scope">{{ scope.row.item_name }}</template>
        </el-table-column>

        <el-table-column prop="base_price" label="配送价" align="center">
        </el-table-column>

        <el-table-column prop="sale_price" label="零售价" align="center">
        </el-table-column>

        <el-table-column prop="dis_price" label="折扣价" align="center">
        </el-table-column>
      </el-table>
    </div>

    <!-- 导入产品 -->
    <el-drawer
      title="导入产品"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div style="width: 90%; margin: 0 auto">
        示例:
        <div style="font-size: 12px; color: #889aa4">
          <pre>
6941196077497
6941196071990
</pre
          >
        </div>
        <el-input
          type="textarea"
          placeholder="输入产品SKU,每行一个SKU"
          rows="15"
          v-model="goodsCodeS"
        ></el-input>

        <el-button type="success" style="margin: 10px" @click="implodeSubmit"
          >提交</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goodsCodeS: "",
      disName: "活动详情",
      disId: 0,
      drawer: false,
      direction: "rtl",
      tableData: [],
      multipleSelection: [],
    };
  },
  created() {
    this.getDisId(); //获取到ID
    this.getData(); //获取到数据
    this.getDisInfo(); //获取详情
  },
  methods: {
    //导入商品提交
    implodeSubmit() {
      let postData = { goods_code: [] };
      postData.goods_code = this.goodsCodeS.split("\n");
      this.$axios
        .post("/a1/dis/importList/" + this.disId, postData)
        .then((res) => {
          this.$message.success("导入成功");
          this.getData();
          this.drawer = false;
        });
    },
    //显示导入的界面
    importShow() {
      this.goodsCodeS = "";
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //批量删除
    selDel() {
      if (this.multipleSelection.length > 0) {
        let ids = { ids: [] };
        let goodsPush = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let theGoodsCode = this.multipleSelection[i].id;
          goodsPush.push(theGoodsCode);
        }
        ids.ids = goodsPush;
        this.$axios
          .delete("/a1/dis/delDisList", {
            data: ids,
          })
          .then((res) => {
            this.$message.success("删除成功");
            this.getData();
          });
      } else {
        this.$message.info("请选择商品");
      }
    },
    //获取活动详情
    getDisInfo() {
      this.$axios.get("/a1/dis/" + this.disId).then((res) => {
        this.disName = res.data.data.name;
      });
    },
    getDisId() {
      var query = this.$route.query;
      this.disId = query.id;
    },
    getData() {
      this.$axios.get("/a1/dis/GetGoods/" + this.disId).then((res) => {
        this.tableData = res.data.data;
      });
    },
    //选中操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style>
.disListDiv {
  border: 1px solid #889aa4;
  padding: 10px;
  margin: 0 auto;
  width: 98%;
  border-radius: 8px;
}
</style>
